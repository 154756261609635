import React, { useCallback, useMemo } from "react";

import {
	DisclosureCollapse,
	ListboxSimple,
	TextArea,
} from "../../tailwindComponents";
import ResetFilter from "../../tailwindComponents/ResetFilter";
import AssignationAndDetails from "../assignationAndDetails";
import SectionBloc from "./SectionBloc";
import SectionTimePicker from "./SectionTimePicker";
import { Controller, useFormContext } from "react-hook-form";
import {
	convertHourDuration,
	getTaskSourceModel,
} from "../../../helpers/_functions";
import moment from "moment-timezone";

export default function DisclosureBlocHour({
	t,
	day,
	error,
	setErrors,
	showAssignation,
	workedHoursTypes,
	workingHour,
	isOpen,
	//defaultOpen,
	hoursType,
	mutableProjects,
	removeBlocItem,
	index,
	indexParent,
	setRemovedHourIds,
	blocHourIndex,
	BlocDayWorkingHours,
	allowEditWorkingHours,
	allowAddFuturWorkingHours,
}) {
	const { control, setValue, clearErrors, trigger, getValues, watch } =
		useFormContext();

	const getFormName = useCallback(
		(field) => {
			return `BlocDays.${indexParent}.WorkingHours.${index}.${field}`;
		},
		[index, indexParent]
	);
	const nextStartDateFormName =
		index < BlocDayWorkingHours.length - 1 &&
		`BlocDays.${indexParent}.WorkingHours.${index + 1}.startDate`;

	const formNames = useMemo(
		() =>
			[
				"durationHours",
				"durationMinutes",
				"assignation",
				"detail",
				"startDate",
				"endDate",
				"EmployerAssignment",
				"WorkedHoursTypes",
				"hoursWorking",
				"new",
				"disabled",
			].reduce((acc, name) => ({ ...acc, [name]: getFormName(name) }), {
				durationHours: "",
				durationMinutes: "",
				startDate: "",
				endDate: "",
				assignation: "",
				detail: "",
				EmployerAssignment: "",
				WorkedHoursTypes: "",
				hoursWorking: "",
				new: "",
			}),
		[getFormName]
	);

	const removeHourItem = () => {
		const _NextBlocDays = `BlocDays.${indexParent}.WorkingHours.${index + 1}`;
		if (workingHour._id) {
			setRemovedHourIds((_ids) => [..._ids, workingHour._id]);
		}
		if (
			hoursType?._id === "blocs" &&
			index &&
			BlocDayWorkingHours.length - 1 > index
		) {
			const prevEndDate = getValues(
				`BlocDays.${indexParent}.WorkingHours.${index - 1}.endDate`
			);
			setValue(`${_NextBlocDays}.startDate`, prevEndDate);
			setValue(
				`${_NextBlocDays}.durationHours`,
				getValues(`${_NextBlocDays}.durationHours`)
			);
			setValue(
				`${_NextBlocDays}.durationMinutes`,
				getValues(`${_NextBlocDays}.durationMinutes`)
			);
		}
		removeBlocItem(index);
		setErrors((_errors) => {
			let WorkingHours = _errors?.[indexParent]?.WorkingHours;
			if (WorkingHours) {
				delete WorkingHours?.[index];
				_errors[indexParent].WorkingHours = Object.keys(WorkingHours).reduce(
					(errors, _index) => ({
						...errors,
						[_index < index ? _index : _index - 1]: WorkingHours[_index],
					}),
					{}
				);
			}
			return _errors;
		});
	};
	const hourDuration = useMemo(() => {
		if (!workingHour.startDate || !workingHour.endDate) return "";
		const hour = moment(workingHour.endDate).diff(
			workingHour.startDate,
			"hour",
			true
		);
		return convertHourDuration(hour);
	}, [workingHour.startDate, workingHour.endDate]);

	const disableEditHour = useMemo(
		// @ts-ignore
		() => Boolean(workingHour?._id) && !allowEditWorkingHours,
		[workingHour, allowEditWorkingHours]
	);

	return (
		<div className="arh-relative arh-mb-2 arh-w-full">
			<DisclosureCollapse
				title={`Horaire #${blocHourIndex + 1}: ${hourDuration}`}
				defaultOpen={true}
				width="arh-w-[98%]"
				icon={null}
				titleClass=""
				otherTitle={null}
				unmount={false}
				invalid={Boolean(error)}
			>
				<div className="arh-flex  arh-flex-col">
					<div className="arh-flex arh-justify-between arh-space-x-4">
						{hoursType?._id === "hours" ? (
							<Controller
								control={control}
								name={formNames.hoursWorking}
								defaultValue={{
									key: "selection",
									// @ts-ignore
									startDate: workingHour?.startDate,
									// @ts-ignore
									endDate: workingHour?.endDate,
									color: "#FCB698",
								}}
								disabled={disableEditHour}
								rules={{
									validate: {
										required: () =>
											Boolean(watch(formNames.startDate)) &&
											Boolean(watch(formNames.endDate)),
										startIsBeforeEnd: () =>
											moment(watch(formNames.startDate)).isBefore(
												watch(formNames.endDate),
												"minutes"
											),
										endIsBeforeNowIfFutureAddDenied: () =>
											Boolean(allowAddFuturWorkingHours) ||
											moment(watch(formNames.endDate)).isBefore(
												moment(),
												"minutes"
											),
										isNotHourSuperposed: () =>
											!BlocDayWorkingHours?.some(
												(workingHour, hourIndex) =>
													workingHour.type === "hour" &&
													hourIndex !== index &&
													workingHour.startDate &&
													workingHour.endDate &&
													!(
														moment(watch(formNames.endDate)).isSameOrBefore(
															workingHour.startDate,
															"minutes"
														) ||
														moment(watch(formNames.startDate)).isSameOrAfter(
															workingHour.endDate,
															"minutes"
														)
													)
											),
										isNotCongeSuperposed: () =>
											!BlocDayWorkingHours?.some(
												(workingHour, hourIndex) =>
													workingHour.type === "conge" &&
													hourIndex !== index &&
													workingHour.startDate &&
													workingHour.endDate &&
													!(
														moment(watch(formNames.endDate)).isSameOrBefore(
															workingHour.startDate,
															"minutes"
														) ||
														moment(watch(formNames.startDate)).isSameOrAfter(
															workingHour.endDate,
															"minutes"
														)
													)
											),
									},
								}}
								render={({
									field: { value, name, disabled },
									fieldState: { invalid },
								}) => (
									<SectionTimePicker
										isOpen={isOpen}
										t={t}
										name={name}
										value={value}
										onChange={(startDate, endDate) => {
											if (startDate)
												startDate = moment(day)
													.set({
														h: moment(startDate).hours(),
														m: moment(startDate).minutes(),
													})
													.toDate();
											if (endDate)
												endDate = moment(day)
													.set({
														h: moment(endDate).hours(),
														m: moment(endDate).minutes(),
													})
													.toDate();
											setValue(formNames.startDate, startDate);
											setValue(formNames.endDate, endDate);
											setValue(formNames.hoursWorking, {
												key: "selection",
												startDate,
												endDate,
												color: "#FCB698",
											});
											if (startDate && endDate) setValue(formNames?.new, false);
										}}
										// disabled={Boolean(getValues(`BlocDays.${indexParent}.WorkingHours.${index}.disable`))}
										disabled={disabled}
										invalid={invalid}
										trigger={trigger}
										control={control}
									/>
								)}
							/>
						) : (
							<SectionBloc
								t={t}
								index={index}
								watch={watch}
								setValue={setValue}
								formNames={formNames}
								clearErrors={clearErrors}
								trigger={trigger}
								// disabled={Boolean(getValues(`BlocDays.${indexParent}.WorkingHours.${index}.disable`))}
								disabled={disableEditHour}
								control={control}
								BlocDayWorkingHours={BlocDayWorkingHours}
								nextStartDateFormName={nextStartDateFormName}
								allowAddFuturWorkingHours={allowAddFuturWorkingHours}
							/>
						)}

						<div className="arh-grid arh-grid-cols-2 arh-gap-4 arh-w-full">
							{workedHoursTypes?.length ? (
								<Controller
									control={control}
									name={formNames.WorkedHoursTypes}
									render={({ field: { value, name, onChange } }) => (
										<ListboxSimple
											customButtonClass="arh-h-11"
											placeholder={t("Type")}
											name={name}
											classParentList={`arh-max-w-[265px] arh-mt-auto `}
											label={t("Type")}
											optionList={workedHoursTypes}
											optionTextAttribut="designation"
											selectedValue={value}
											// disabled={Boolean(getValues(`BlocDays.${indexParent}.WorkingHours.${index}.disable`))}
											setSelectedValue={onChange}
											noRadio
											withTooltip
											showReset
										/>
									)}
								/>
							) : (
								""
							)}
							{showAssignation ? (
								<Controller
									name={formNames.assignation}
									control={control}
									render={({ field: { value, onChange } }) => (
										<AssignationAndDetails
											showDetails={false}
											classButton="arh-h-11"
											showAssignation={showAssignation}
											assignation={value}
											// disabled={Boolean(firstHour.disable)}
											setAssignation={(value) => {
												onChange(value.task);
												setValue(formNames.EmployerAssignment, {
													assignement: value?.task?._id,
													assignementTitle: value?.task?.title,
													sourceModel: getTaskSourceModel(value?.task),
												});
											}}
											parentClass="arh-mb-0 arh-w-[30%]  arh-mt-auto"
											mutableProjects={mutableProjects}
											classParentAssignation="arh-flex arh-flex-col arh-justify-end arh-h-full"
										/>
									)}
								/>
							) : (
								""
							)}
						</div>
					</div>

					{showAssignation ? (
						<div className="arh-mt-2">
							<Controller
								name={formNames.detail}
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<TextArea
										label={t("Détails")}
										name={name}
										placeholder={t("Détails")}
										value={value}
										// disabled={Boolean(getValues(`BlocDays.${indexParent}.WorkingHours.${index}.disable`))}
										onChange={onChange}
									/>
								)}
							/>
						</div>
					) : (
						""
					)}
				</div>
			</DisclosureCollapse>
			<div className="arh-absolute arh-right-5 arh-top-8">
				{!disableEditHour && <ResetFilter onClick={removeHourItem} />}
			</div>
		</div>
	);
}
