import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import "../../assets/css/pages/pointeuseExterne.css";
import Default from "../../assets/image/defaultUser.png";
import BtnPointeuse from "../../components/BtnPointeuse/btnPointeuse";
import DataImage from "../../services/dataImage.service";
import { EmployerHours, WorkingHour, LockedDayService } from "../../services";
import { Modal, ModalBody } from "reactstrap";
import ModalWebcam from "../../components/Modal/prisePhotoExterne";
import moment from "moment-timezone";
import PointageArrêtéAvecAssignation from "../Modal/pointageArrêtéAssignation";
import PointageArrêtéSansAssignation from "../Modal/pointageArrêté";
import SaveHours from "../Modal/enregistrementDesHeures";
import SaveBlocksHours from "../Modal/SaveMyWorkedHours";
import { MODEL_NAME_WORKING_HOUR_POINTING } from "../../helpers/_constants";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  AUCUN,
  DECLARATION_DES_HEURES,
  POINTAGE_AVEC_PHOTO,
  POINTAGE_SANS_PHOTO,
  ENREGISTREMENT_DES_BLOCS_D_HEURES,
  ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE
} from "../../helpers/_constants";
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import { createFileName } from "../../helpers/_functions";
import { useNotification } from "../../hooks/useNotification";
import ModalHourMultiple from "../Modal/HoursBlocMultiple";

let captureInterval = null;

const ItemsPointeuse = ({
  employer,
  enterpriseId,
  hoursTypes,
  mutableProjects
}) => {
  const { showInfo, showError, showSuccess } = useNotification();
  const [activePointeuse, setActivePointeuse] = useState(false);
  const [canPoint, setStatusPoint] = useState(true);
  const [employerHours, setEmployerHours] = useState({});
  const [workingHour, setWorkingHour] = useState({});
  const [modalwebcam, setModalWebcam] = useState(false);
  const [modalMultiple, setModalMultiple] = useState(false);
  const [already, setAlready] = useState(true);
  const [modalWebcamIsHidden, setModalWebcamIsHidden] = useState(false);
  const [
    modalPointingStoppedWithAssignation,
    setModalPointingStoppedWithAssignation
  ] = useState(false);
  const [
    modalPointingStoppedWithoutAssignation,
    setModalPointingStoppedWithoutAssignation
  ] = useState(false);
  const [modalSaveHours, setModalSaveHours] = useState(false);

  const [modalSaveBlocksHours, setModalSaveBlocksHours] = useState(false);

  const toggleModalSaveBlocksHours = () => {
    setModalSaveBlocksHours((_modalSaveBlocksHours) => !_modalSaveBlocksHours);
    setStatusPoint(true);
  };

  // const reduxDispatch = useDispatch();
  const { enterpriseName } = useParams();
  const webcamRef = useRef(null);
  const { t } = useTranslation("timeClock");

  const getData = () => {
    let dataEmployerHours = employer.employerHours;
    if (dataEmployerHours) setEmployerHours(dataEmployerHours);
    const workingHours = dataEmployerHours?.WorkingHours;
    let lastWorkingHour = workingHours?.find((data) => !data?.endDate);
    if (lastWorkingHour) {
      setWorkingHour(lastWorkingHour);
      if (!activePointeuse) setActivePointeuse(true);
    } else {
      if (activePointeuse) setActivePointeuse(false);
    }
  };

  useEffect(() => {
    if (employer?._id) {
      getData();
    }
    // eslint-disable-next-line
  }, [employer]);

  const setCatchError = (err) => {
    const message =
      err?.message === "Network Error"
        ? "network_error"
        : err?.message === "error_webcam"
          ? "error_webcam"
          : Boolean(err?.message)
            ? err?.message
            : "error_occured";
    showError(message);
    // reduxDispatch({
    //   type: "POINTER_ERROR",
    //   payload: {
    //     message: message
    //   }
    // });
    // setTimeout(() => {
    //   reduxDispatch({
    //     type: "POINTER_ERROR",
    //     payload: {
    //       message: ""
    //     }
    //   });
    // }, 3000);
  };

  // const setCatchSuccess = (message) => {
  //   reduxDispatch({
  //     type: "STATUS_POINTER",
  //     payload: {
  //       message: message
  //     }
  //   });
  //   setTimeout(() => {
  //     reduxDispatch({
  //       type: "STATUS_POINTER",
  //       payload: {
  //         message: ""
  //       }
  //     });
  //   }, 3000);
  // };

  const togglewebcam = () => {
    setModalWebcam(!modalwebcam);
    setStatusPoint(true);
  };

  const toggleModalWebcamWithIsHidden = () => {
    setModalWebcamIsHidden((_modalWebcamIsHidden) => !_modalWebcamIsHidden);
  };

  const updateWorkingHour = async (
    defaultValues,
    workingHourParams,
    currentDate
  ) => {
    let startMoment = null;
    let endMoment = null;
    if (currentDate?.selectedDate) {
      startMoment = moment(currentDate?.selectedDate?.startDate);
      endMoment = moment(currentDate?.selectedDate?.endDate);
    } else {
      if (workingHourParams?.startDate)
        startMoment = moment(workingHourParams?.startDate);
      if (currentDate) endMoment = moment(currentDate);
    }

    let dataWorkingHours = {
      ...defaultValues,
      ...workingHourParams
    };

    if (startMoment) {
      dataWorkingHours.startDate = startMoment.toDate();
    }
    if (endMoment) {
      dataWorkingHours.endDate = endMoment.toDate();
    }

    try {
      const response = await WorkingHour.updateById(dataWorkingHours);
      if (!response?.data?.success) {
        setCatchError({ message: response?.data?.message });
        return;
      }

      const idWorkingHours = response?.data?.data?.map((wh) => wh._id);

      if (idWorkingHours?.length > 1) {
        let newEmployerHour = { ...employerHours };
        // système de decoupage: 1er element modifié, les autres ajoutés
        idWorkingHours.shift();
        newEmployerHour.WorkingHours.push(...idWorkingHours);
        newEmployerHour.streamer = employer?._id;
        await EmployerHours.updateById(newEmployerHour);
      }

      setModalSaveHours(false);
      setWorkingHour(null);
      setActivePointeuse(false);
      setStatusPoint(true);
      if (
        [POINTAGE_SANS_PHOTO, POINTAGE_AVEC_PHOTO].includes(
          employerHours?.pointingType
        )
      ) {
        toggleModalPointingStoppedWithoutAssignation();
        setTimeout(() => {
          setModalPointingStoppedWithoutAssignation(false);
        }, 1000);
      }
      if (employerHours?.pointingType === DECLARATION_DES_HEURES) {
        showSuccess("Horaire enregistrée !");
      }
    } catch (err) {
      pointingError(err);
    }
  };

  const startPointeuse = async ({
    currentDate = null,
    dataImageId = null
  } = {}) => {
    try {
      setStatusPoint(false);

      let dataWorkingHours = {
        Employer: employer?._id,
        Enterprise: enterpriseId,
        EmployerAssignment: null
      };
      if (currentDate) {
        dataWorkingHours.startDate = currentDate;
      }
      if (typeof dataImageId === "string") {
        dataWorkingHours.images = [dataImageId];
      }

      dataWorkingHours.employerHours = { ...employerHours };

      const response = await WorkingHour.insert(dataWorkingHours);
      if (response.status === 200) setActivePointeuse(true);

      let newEmployerHour = { ...employerHours };
      const newWorkingHours = response?.data?.data;
      if (newWorkingHours?._id) {
        newEmployerHour.WorkingHours = [newWorkingHours?._id];
        if (
          newEmployerHour?.isFirstPointing &&
          newEmployerHour?.pointingType === POINTAGE_AVEC_PHOTO
        ) {
          newEmployerHour.isFirstPointing = false;
        }
        newEmployerHour.streamer = employer?._id;
        setWorkingHour(newWorkingHours);
        showSuccess("Pointage Démarré !");
      }
      setEmployerHours(newEmployerHour);
    } catch (error) {
      pointingError(error);
    } finally {
      setStatusPoint(true);
    }
  };

  const stopPointeuse = async ({
    currentDate = null,
    assignation = null,
    dataImageId = null,
    WorkedHoursTypes
  } = {}) => {
    try {
      let sourceModel = null;

      if (assignation?.task?.Project) {
        sourceModel = "Assignement";
      } else if (assignation?.task?.parentModel) {
        sourceModel = "Task";
      } else if (assignation?.task?._id === "formation") {
        sourceModel = "Formation";
      } else if (assignation?.task?._id) {
        sourceModel = "Project";
      }

      if (workingHour?.images) {
        workingHour.images.push(dataImageId);
      }

      let defaultValues = {
        Employer: workingHour?.Employer || employer?._id,
        Enterprise: workingHour?.Enterprise || enterpriseId,
        Timezone: moment.tz.guess(),
        assignement: assignation?.task?._id || null,
        detail: assignation?.detail || null,
        assignementTitle: assignation?.title || null,
        sourceModel,
        WorkedHoursTypes
      };
      if (workingHour?._id) defaultValues._id = workingHour._id;
      await updateWorkingHour(defaultValues, workingHour, currentDate);
    } catch (error) {
      pointingError(error);
    }
  };

  const toggleModalPointingStoppedWithAssignation = (param) => {
    setModalPointingStoppedWithAssignation(
      param !== undefined ? param : !modalPointingStoppedWithAssignation
    );
    setStatusPoint(true);
  };

  const toggleModalPointingStoppedWithoutAssignation = () => {
    setModalPointingStoppedWithoutAssignation(
      !modalPointingStoppedWithoutAssignation
    );
  };

  const toggleModalSaveHours = () => {
    setModalSaveHours(!modalSaveHours);
    setStatusPoint(true);
  };

  const toggleModalSaveMultiple = () => {
    setModalMultiple(!modalMultiple);
    setStatusPoint(true);
  };

  const capture = () => {
    captureInterval = setInterval(() => {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          clearInterval(captureInterval);
          const fullName = createFileName(employer);
          var image = dataURLtoFile(imageSrc, fullName + ".jpg");
          // insert image into dataImage
          const formData = new FormData();
          formData.append("source", employer._id);
          formData.append("key", "pointage/");
          formData.append("sourceModel", MODEL_NAME_WORKING_HOUR_POINTING);
          formData.append(
            "enterpriseId",
            workingHour?.Enterprise || enterpriseId
          );
          formData.append("enterpriseName", enterpriseName);
          formData.append("images", image);
          DataImage.insert(formData)
            .then((res) => {
              if (res.data.success) {
                startPointeuse({ dataImageId: res?.data?.data._id });
              }
            })
            .catch((err) => {
              pointingError(err);
            })
            .finally(() => {
              setAlready(true);
              setModalWebcamIsHidden(false);
            });
        }
      }
    }, 1000);
  };

  const captureClose = (assignationAndHoursType = {}) => {
    captureInterval = setInterval(() => {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          clearInterval(captureInterval);
          setStatusPoint(true);
          const fullName = createFileName(employer);
          var image = dataURLtoFile(imageSrc, fullName + ".jpg");
          // insert image into dataImage
          const formData = new FormData();
          formData.append("source", employer._id);
          formData.append("key", "pointage/");
          formData.append("sourceModel", MODEL_NAME_WORKING_HOUR_POINTING);
          formData.append(
            "enterpriseId",
            workingHour?.Enterprise || enterpriseId
          );
          formData.append("enterpriseName", enterpriseName);
          formData.append("images", image);
          DataImage.insert(formData)
            .then((res) => {
              if (res.data.success) {
                stopPointeuse({
                  ...assignationAndHoursType,
                  dataImageId: res?.data?.data._id
                });
              }
            })
            .catch((err) => {
              pointingError(err);
            })
            .finally(() => {
              setAlready(true);
              toggleModalWebcamWithIsHidden();
              toggleModalPointingStoppedWithAssignation(false);
            });
        }
      }
    }, 1000);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl?.split(",");
    if (arr?.[0]) {
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }
  };

  const pointingError = (err) => {
    console.error(err);
    clearInterval(captureInterval);
    if (modalWebcamIsHidden) setModalWebcamIsHidden(false);
    if (modalwebcam) setModalWebcam(false);
    if (modalSaveHours) setModalSaveHours(false);
    if (modalMultiple) setModalMultiple(false);
    if (modalPointingStoppedWithAssignation)
      setModalPointingStoppedWithAssignation(false);
    if (modalPointingStoppedWithoutAssignation)
      setModalPointingStoppedWithoutAssignation(false);
    setStatusPoint(true);
    setCatchError(err);
  };

  const OnClickPointeuse = async () => {
    setStatusPoint(false);
    const pointingType = employerHours?.pointingType;

    // stop pointeuse
    if (pointingType === DECLARATION_DES_HEURES) {
      toggleModalSaveHours();
      return;
    }
    if (pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES) {
      toggleModalSaveBlocksHours();
      return;
    }
    if (pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE) {
      toggleModalSaveMultiple();
      return;
    }
    if (
      activePointeuse &&
      [POINTAGE_SANS_PHOTO, POINTAGE_AVEC_PHOTO].includes(pointingType) &&
      workingHour
    ) {
      if (employerHours?.showAssignation || hoursTypes?.length) {
        toggleModalPointingStoppedWithAssignation();
        return;
      }
      if (pointingType === POINTAGE_AVEC_PHOTO) {
        closePointeuse();
        return;
      }
      stopPointeuse();
      return;
    }

    // start pointeuse
    if (!activePointeuse) {
      if (pointingType === POINTAGE_SANS_PHOTO) {
        setActivePointeuse(true);
        startPointeuse();
        return;
      }
      if (employerHours?.isFirstPointing) {
        togglewebcam();
        return;
      }
      toggleModalWebcamWithIsHidden();
      return;
    }
    pointingError();
  };

  const closePointeuse = (assignationAndHoursType = {}) => {
    toggleModalWebcamWithIsHidden();
    if (already) {
      setAlready(false);
      captureClose(assignationAndHoursType);
    }
  };

  const userMediaError = (err) => {
    console.error(err);
    pointingError({ message: "error_webcam" });
  };

  // const showSuccess = (message) => setCatchSuccess(message);

  const saveWorkingHours = async (blocksHours) => {
    try {
      if (blocksHours?.initialDate && employer?._id) {
        let resp = await LockedDayService.checkLockDay({
          employeeIds: [employer._id],
          day: moment(blocksHours.initialDate).format("YYYY/MM/DD")
        });
        if (resp.data?.data) {
          showError("Certains jours de cette plage horaire sont verrouillés");
          return;
        }
      }
      blocksHours = {
        ...blocksHours,
        Timezone: moment.tz.guess(),
        Employer: employer._id,
        Enterprise: enterpriseId
      };
      const response = await WorkingHour.createBlocksHours(blocksHours);

      if (!response?.data?.success) {
        setStatusPoint(true);
        showError(
          response?.data?.message ||
          "Vous avez déjà des heures travaillés dans cette plage d'horaire"
        );
        return;
      }
      setStatusPoint(true);
      setModalSaveBlocksHours(false);
      setWorkingHour(null);
      setActivePointeuse(false);
      showSuccess("Horaires enregistrées !");
    } catch (error) {
      setStatusPoint(true);
      showError(error);
    }
  };

  // const showError = (message) => setCatchError({ message });
  const onPointing = async () => {
    const { innerWidth: width, innerHeight: height } = window;
    if (
      (width < 1160 || height < 370) &&
      employerHours?.pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE
    )
      return showInfo(
        "Votre type de pointage n'est pas disponible pour cet appareil"
      );
    if (canPoint) await OnClickPointeuse();
  };

  return (
    <>
      <div className="itemsPointeuse d-flex flex-wrap flex-justify-between flex-align-center">
        <div className="content_infopers_pointeuse d-flex flex-align-center">
          <div className="avatar_pers_pointeuse">
            <img
              src={employer?.profilImage || Default}
              alt="User"
            />
          </div>
          <label className="name_employer_point regular">
            {employer?.fullname}
          </label>
        </div>

        {employerHours?.pointingType &&
          employerHours?.pointingType !== AUCUN ? (
          <BtnPointeuse
            onChange={onPointing}
            canPoint={canPoint}
            activePointeuse={activePointeuse}
            pointingType={employerHours?.pointingType}
          />
        ) : (
          <div className="content_infopers_pointeuse d-flex flex-align-center">
            <label className="name_employer_point regular">
              {t("Pas de mode de pointage")}
            </label>
          </div>
        )}
      </div>
      {modalWebcamIsHidden ? (
        <Webcam
          className="w-100 webcam_hidden"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ zIndex: "1" }}
          onUserMedia={() => {
            if (already) {
              setAlready(false);
              capture();
            }
          }}
          onUserMediaError={userMediaError}
        />
      ) : (
        ""
      )}

      <Modal
        isOpen={modalPointingStoppedWithAssignation}
        className="alt_modal_container alt_modal_container--stopPointing"
        centered={true}
        toggle={toggleModalPointingStoppedWithAssignation}
      >
        <ModalBody>
          <PointageArrêtéAvecAssignation
            onClickClose={() => {
              setModalPointingStoppedWithAssignation(false);
            }}
            hoursTypes={hoursTypes}
            employerHours={employerHours}
            closePointeuse={closePointeuse}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            OnClickPointeuse={OnClickPointeuse}
            showAssignation={employerHours?.showAssignation}
            mutableProjects={mutableProjects}
            stopPointeuse={stopPointeuse}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalPointingStoppedWithoutAssignation}
        className="alt_modal_container alt_modal_container--stop"
        centered={true}
      >
        <ModalBody>
          <PointageArrêtéSansAssignation />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalwebcam}
        className={
          employerHours?.isFirstPointing
            ? "alt_modal_container alt_modal_container--low alt_modal_container--firstwebcam"
            : "alt_modal_container alt_modal_container--low alt_modal_container--secondWebcam"
        }
        centered={true}
        toggle={togglewebcam}
      >
        <ModalBody>
          <ModalWebcam
            onClickClose={togglewebcam}
            employerHours={employerHours}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            activePointeuse={activePointeuse}
            OnClickPointeuse={OnClickPointeuse}
            enterpriseId={workingHour?.Enterprise || enterpriseId}
            startPointeuse={startPointeuse}
            isFirstPointing={employerHours?.isFirstPointing}
          />
        </ModalBody>
      </Modal>
      <SaveHours
        isOpen={modalSaveHours}
        hoursTypes={hoursTypes}
        toggle={toggleModalSaveHours}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        stopPointeuse={stopPointeuse}
        // workingHourStartDate={workingHour?.startDate}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
        employeId={employer._id}
        enterpriseId={enterpriseId}
      />
      <SaveBlocksHours
        isOpen={modalSaveBlocksHours}
        canPointing={canPoint}
        toggle={toggleModalSaveBlocksHours}
        showError={showError}
        employeId={employer._id}
        workedHoursTypes={hoursTypes}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        saveWorkingHours={saveWorkingHours}
        hoursWork={workingHour}
      // allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
      />
      <ModalHourMultiple
        isOpen={modalMultiple}
        toggle={toggleModalSaveMultiple}
        showAssignation={employerHours?.showAssignation}
        userId={employer._id}
        enterpriseId={enterpriseId}
        workedHoursTypes={hoursTypes}
        mutableProjects={mutableProjects}
        allowEditWorkingHours={employer.allowEditWorkingHours}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
      />
    </>
  );
};
export default ItemsPointeuse;
