import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect
} from "react";
import { useTranslation } from "react-i18next";
import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "../../../routes/IconeSvg";
import ListboxOption from "./ListboxOption";

function isElementInList(element, list) {
  return list.some((listitem) => listitem._id === element._id);
}

export default function ListboxAssignation(props) {
  const {
    // requiredValues
    placeholder,
    selectedValue,
    setSelectedValue,
    optionList = [],
    readOnly=false,

    invalid = false,
    withSearch = false,
    subItemAttr = "SousGroupes",
    titleAttr = "title",
    setAssignation = (_) => {}
  } = props;
  const { t } = useTranslation("beginEnd.departure");

  const getStrSelectedValue = () => {
    if (selectedValue?.length <= 1 && selectedValue?.[0]?._id !== "formation") {
      return "";
    }

    let text = "";
    for (let item of selectedValue) {
      text += ` / ${item[titleAttr]}`;
    }

    return text.substring(2, text.length);
  };

  function onItemClicked(selectedItems = []) {
    setSelectedValue(selectedItems);
  }

  useEffect(() => {
    // Ajust selected value for assignation saving
    if (selectedValue?.length > 1 || selectedValue?.[0]?._id === "formation")
      setAssignation({
        task: selectedValue[selectedValue.length - 1],
        title: getStrSelectedValue()
      });
    else setAssignation({});
    // eslint-disable-next-line
  }, [selectedValue]);

  const isItemSelected = useCallback(
    (item) => {
      return (
        (selectedValue?.length > 1 ||
          selectedValue?.[0]?._id === "formation") &&
        isElementInList(item, selectedValue)
      );
    },
    [selectedValue]
  );

  const isItemExpanded = useCallback(
    (item) => {
      return isElementInList(item, selectedValue);
    },
    [selectedValue]
  );

  // eslint-disable-next-line
  const selectTextValue = useMemo(getStrSelectedValue, [selectedValue]);

  // eslint-disable-next-line
  const [searchValue, setSearchValue] = useState("");
  const filteredOptionList = useMemo(() => {
    if (!Array.isArray(optionList)) {
      console.error("optionList should be an array");
      return [];
    }

    if (!withSearch || !searchValue) {
      return optionList;
    }

    const upperCaseSearch = searchValue.toUpperCase();
    return optionList.filter((item) => {
      if (item[titleAttr].toUpperCase().includes(upperCaseSearch)) {
        return true;
      }

      return item.SousGroupes.some((subItem) =>
        subItem[titleAttr].toUpperCase().includes(upperCaseSearch)
      );
    });
  }, [searchValue, withSearch, optionList, titleAttr]);

  // function resetValue(e) {
  //   e.stopPropagation();
  //   setSelectedValue([]);
  // }

  return (
    <Listbox multiple>
      {({ open }) => (
        <div className="arh-relative arh-w-full">
          <Listbox.Button
            className={`arh-relative arh-w-full arh-cursor-default arh-rounded arh-bg-white arh-h-11 arh-py-3 arh-pl-6 arh-pr-8 arh-text-left arh-border arh-border-solid arh-border-[#ECECEC] arh-font-poppins arh-font-normal arh-text-xs placeholder:arh-text-[#8B8F91] focus:arh-outline-none focus-visible:arh-border-[#ECECEC] focus-visible:arh-ring-2 focus-visible:arh-ring-[#ECECEC] focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor ${
              invalid &&
              " arh-text-[#F60A20] arh-bg-[#FFF3F4] arh-border-[#F60A20] placeholder:arh-text-[#F60A20]"
            }`}
            as="div"
          >
            <input
              className={`arh-block arh-truncate arh-max-w-full arh-w-full arh-h-full arh-text-ellipsis arh-border-0 arh-font-poppins arh-font-normal arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-[#8B8F91] focus:arh-outline-none focus-visible:!arh-border-0 ${
                invalid && "arh-text-[#F60A20]"
              }`}
              type="text"
              placeholder={placeholder}
              value={t(selectTextValue)}
              readOnly={readOnly}
            />
            <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="arh-transition arh-ease-in arh-duration-100"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <Listbox.Options className="arh-absolute arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-py-6 arh-px-4 arh-text-xs arh-shadow-xs arh-z-20 ">
              {filteredOptionList.map((item) => (
                <ListboxOption
                  key={item._id}
                  item={item}
                  list={[item]}
                  subItemAttr={subItemAttr}
                  titleAttr={titleAttr}
                  onItemClicked={onItemClicked}
                  isItemSelected={isItemSelected}
                  isItemExpanded={isItemExpanded}
                  color={props.color}
                />
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
