import moment from "moment-timezone";
import { createTheme } from "@mui/material/styles";
import { ERROR, INFO_ACTION, SUCCESS_ACTION, WARNING_ACTION } from "./_constants";

//date picker helper
export const timePickerTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        sizeMedium: {
          color: "#292929",
          backgroundColor: "#F9FAFC"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#fcb698",
      contrastText: "#fff"
    }
  }
});

export const twoDigit = (value) => {
  const parsedValue = parseInt(value);
  return parsedValue < 10 ? "0" + parsedValue : value;
};

function isPreviousButton(element) {
  return (
    element?.firstElementChild &&
    element?.firstElementChild?.className?.includes("years-previous")
  );
}

function isUpCummingButton(element) {
  return (
    element?.firstElementChild &&
    element?.firstElementChild?.className?.includes("years-upcoming")
  );
}

function textContent(element) {
  return +element?.innerText?.replace("✓\n", "") || "";
}

function sortYear(a, b) {
  if (isPreviousButton(a) || isUpCummingButton(b)) {
    return -1;
  }
  if (isUpCummingButton(a) || isPreviousButton(b)) {
    return 1;
  }

  return textContent(a) - textContent(b);
}

export function reverseDropwdownYear() {
  setTimeout(() => {
    const yearDropdown = this.firstElementChild;
    if (yearDropdown?.childNodes?.length) {
      let childElements = Array.from(yearDropdown.childNodes);
      childElements.sort(sortYear);
      yearDropdown.append(...childElements);
    }
    if (document.querySelector(".react-datepicker__year-dropdown")) {
      let top = document.querySelector(
        ".react-datepicker__year-option--selected_year"
      ).offsetTop;
      document
        .querySelector(".react-datepicker__year-dropdown")
        .scroll(0, top - 5);
    }
  }, 0); //<- permet d'attendre que le dropdown s'affiche
}
// export const calculateTimes = (endTimes, startTimes) => {
//   let duration = moment.duration(moment(endTimes).diff(startTimes));
//   // duration in hours
//   let hours = parseInt(duration.asHours());
//   // duration in minutes
//   let minutes = parseInt(duration.asMinutes()) % 60;
//   hours = hours < 0 ? 0 : hours;
//   minutes = minutes < 0 ? 0 : minutes;
//   return { hours, minutes };
// };

export function diacriticSensitiveRegex(string = "") {
  return string
    .replaceAll(/ae/g, "[ae,æ]")
    .replaceAll(/oe|œ/g, "[oe,œ]")
    .replaceAll(/AE|Æ/g, "[AE,Æ]")
    .replaceAll(/OE|Œ/g, "[OE,Œ]")
    .replaceAll(/a|á|à|â|ä|å|ã/g, "[a,á,à,â,ä,å,ã]")
    .replaceAll(/c|ç/g, "[c,ç]")
    .replaceAll(/e|é|è|ê|ë/g, "[e,é,è,ê,ë]")
    .replaceAll(/i|í|ì|î|ï/g, "[i,í,ì,î,ï]")
    .replaceAll(/n|ñ/g, "[n,ñ]")
    .replaceAll(/o|ó|ò|ô|ö|õ|ø/g, "[o,ó,ò,ô,ö,õ,ø]")
    .replaceAll(/s|š/g, "[s,š]")
    .replaceAll(/u|ú|ù|û|ü/g, "[u,ú,ù,û,ü]")
    .replaceAll(/y|ý|ÿ/g, "[y,ý,ÿ]")
    .replaceAll(/z|ž/g, "[z,ž]")
    .replaceAll(/A|Á|À|Â|Ä|Å|Ã/g, "[A,Á,À,Â,Ä,Å,Ã]")
    .replaceAll(/C|Ç/g, "[C,Ç]")
    .replaceAll(/E|É|È|Ê|Ë/g, "[E,É,È,Ê,Ë]")
    .replaceAll(/I|Í|Ì|Î|Ï/g, "[I,Í,Ì,Î,Ï]")
    .replaceAll(/N|Ñ/g, "[N,Ñ]")
    .replaceAll(/O|Ó|Ò|Ô|Ö|Õ|Ø/g, "[O,Ó,Ò,Ô,Ö,Õ,Ø]")
    .replaceAll(/S|Š/g, "[S,Š]")
    .replaceAll(/U|Ú|Ù|Û|Ü/g, "[U,Ú,Ù,Û,Ü]")
    .replaceAll(/Y|Ý|Ÿ/g, "[Y,Ý,Ÿ]")
    .replaceAll(/Z|Ž/g, "[Z,Ž]")
    .replaceAll(/'|`/g, "[',`]");
}

export const createFileName = (employer) => {
  if (!employer || !employer.fullname) {
    return "";
  }

  return employer.fullname.replaceAll(" ", "_");
};

export const getTimeclockUpdateMsg = (currentTimeclock, newTimeclock) => {
  if (!currentTimeclock || !newTimeclock) return "";
  const currentEmployerNumber = currentTimeclock.Employers?.length || 0;
  const newEmployerNumber = newTimeclock.Employers?.length || 0;
  if (currentEmployerNumber === newEmployerNumber) {
    if (currentTimeclock?.activeStatus !== newTimeclock?.activeStatus) {
      return newTimeclock.activeStatus
        ? "La pointeuse vient d'être re-activée!"
        : "La pointeuse vient d'être désactivée";
    }
    return "";
  }
  if (currentEmployerNumber > newEmployerNumber) {
    return currentEmployerNumber === newEmployerNumber + 1
      ? "Un employé a été supprimé dans la liste"
      : "Des employés ont été supprimés dans la liste";
  }
  return currentEmployerNumber + 1 === newEmployerNumber
    ? "Un employé a été ajouté dans la liste"
    : "Des employés ont été ajoutés dans la liste";
};

export const getFormat = (hourValue) => {
  if (hourValue === "0") return true;
  const formats = [
    "h",
    "hA",
    "hm",
    "hmA",
    "hh",
    "hhA",
    "hhm",
    "hhmA",
    "hmm",
    "hmmA",
    "hhm",
    "hhmA",
    "hhmm",
    "hhmmA",
    "h:",
    "h:A",
    "h:m",
    "h:mA",
    "hh:",
    "hh:A",
    "hh:m",
    "hh:mA",
    "h:mm",
    "h:mmA",
    "hh:m",
    "hh:mA",
    "hh:mm",
    "hh:mmA"
  ];
  for (let format of formats) {
    if (moment(hourValue, format, true).isValid()) return format;
  }
  return false;
};

export const validateInputHourFormat = (hourValue) => {
  if (hourValue === "") return true;
  hourValue = hourValue.toUpperCase();
  hourValue = hourValue.replaceAll(" ", "");
  if (!hourValue.includes("M")) {
    hourValue = hourValue.replaceAll("A", "AM").replaceAll("P", "PM");
  }
  let format = getFormat(hourValue);
  return format;
};

export const scrollIntoView = (el) => {
  if (typeof el?.scrollIntoView === "function") {
    el.scrollIntoView();
    el.classList.add("colorFlash");
  }
};

export const getTaskSourceModel = (
  { _id, parentModel, Project } = {
    _id: null,
    parentModel: null,
    Project: null
  }
) =>
  (Project && "Assignement") ||
  (parentModel && "Task") ||
  (_id === "formation" && "Formation") ||
  (_id && "Project") ||
  null;

export const getDateToRender = (date, timezone = "UTC") => {
  let dateToRender = null;
  if (date && moment(date).isValid())
    dateToRender = moment(moment.tz(date, timezone).format("YYYY-MM-DD HH:mm"))
      .set({ second: 0, millisecond: 0 })
      .toDate();
  return dateToRender;
};

export const setNotificationSuccess = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: SUCCESS_ACTION,
        message
      }
    });
  }, delay);
};
export const setNotificationWarning = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: WARNING_ACTION,
        message
      }
    });
  }, delay);
};

export const setNotificationError = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: ERROR,
        message
      }
    });
  }, delay);
};
export const setNotificationInfo = (dispatch, message, delay = 0) => {
  setTimeout(() => {
    dispatch({
      type: "notification/STANDARD_NOTIFICATION",
      payload: {
        type: INFO_ACTION,
        message
      }
    });
  }, delay);
};

/* Traduction des parties de variables des notifications*/
const translateOptions = (options, translator) => {
  if (options) {
    for (const key in options) {
      options[key] = translator(`message:${options[key]}`);
    }
  }
  return options;
};
/*Function reduite pour la traduction du message et les variables des notifications */
export const translateNotification = (translator, message, option) => {
  return translator(`message:${message}`, translateOptions(option, translator));
};

export const calculateTimes = (startTimes, endTimes) => {
  if (!startTimes || !endTimes)
    return {
      hours: 0,
      minutes: 0
    };
  let hourDuration = moment(endTimes).diff(startTimes, "hours", true);
  return {
    hours: Math.floor(hourDuration),
    minutes: parseInt(((hourDuration * 60) % 60).toFixed(0))
  };
};
export const convertHourDurationToHourAndMinute = (
  hourDuration,
  separatedChar = ":"
) => {
  if (!hourDuration) return "";
  const hours = Math.floor(hourDuration),
    minutes = (hourDuration * 60) % 60;
  return `${twoDigit(hours)}${separatedChar}${twoDigit(minutes?.toFixed(0))}`;
};

export const getRegSearchValue = (name) => {
  let nameReg = null;
  if (name) {
    // Extraction des propriétés des filtres
    const normalized = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const nameParts = normalized.split(/\s+/);

    // Construction de l'expression régulière pour chaque partie du nom
    const nameRegexParts = nameParts.map((part) => `(?=.*${part})`);
    nameReg = new RegExp(nameRegexParts.join(""), "i");
  }
  return nameReg;
};
export const searchValue = (nameReg, source) => {
  return nameReg.test(source.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
};

export const arraySearch = (value, array, attribut) => {
  if (!value) return array;
  const regSearchValue = getRegSearchValue(value);
  const newArray = array.filter((item) =>
    searchValue(regSearchValue, item[attribut])
  );
  return newArray;
};
export const convertHourDuration = (hours) => {
  let h = 0,
    m = 0;
  if (hours) {
    h = parseInt(hours);
    if (Number.isNaN(h)) {
      h = 0;
    } else {
      m = (parseFloat(hours) * 60) % 60;
    }
  }
  return (
    "" + (h > 0 ? h + "h" : "") + (m > 0 ? twoDigit(m.toFixed(0)) + "mn" : "")
  );
};
export const customizeError = (err) => {
  let err_message = "Erreur inconnu!";
  if (err) {
    if (err.error) {
      err = err.error;
    }
    if (err.message) {
      err_message = err.message;
    } else if (err.msg) {
      err_message = err.msg;
    } else if (typeof err.data?.error === "string") {
      err_message = err.data.error;
    }
  }
  let errorCustomized = {
    success: false,
    error: err,
    status: false,
    message: err_message
  };
  return errorCustomized;
};
export const getResponseData = (response, single = false) => {
  if (response?.status === 200) {
    if (single && response.data.data?.[0]) return response.data.data[0];
    else return response.data.data;
  } else {
    return Promise.reject(customizeError(response.data));
  }
};

