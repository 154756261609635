import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import "../../../assets/css/components/mobileTimePicker.css";
import imgClock from "../../../assets/image/icones/icon_clock.svg";
import TimePickerFade from "../../TimePicker/";
import { convertHourDurationToHourAndMinute } from "../../../helpers/_functions";
import { validateInputHourFormat } from "../../../helpers/_functions";
// import { getDateToRender } from "../../../helpers/_functions";
// import { WorkingHour } from "../../../services/";

const InputTimePickerGroup = (props) => {
  const {
    selectedDate,
    trackingHours,
    invalid,
    disabled = false,
    changeTime,
    showError,
    // idEmployer,
    isOpen,
    setIsChangedHour,
    titleButtonSave,
    onAddClicked,
    label,
    hasEndDate,
    parentClass = "!arh-py-2 !arh-px-6 arh-mb-2",
    classSelector = ""
  } = props;
  const [allowedChange, setAllowedChange] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    if (
      selectedDate?.startDate &&
      moment(selectedDate?.startDate).format("hh:mm A") !== startTime
    ) {
      setStartTime(moment(selectedDate?.startDate).format("hh:mm A"));
    }
    if (
      selectedDate?.endDate &&
      moment(selectedDate?.endDate).format("hh:mm A") !== endTime
    ) {
      setEndTime(moment(selectedDate?.endDate).format("hh:mm A"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, selectedDate]);

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" || (e.key === "Delete" && !allowedChange)) {
      setAllowedChange(true);
    }
  };

  const handleChangeStartTime = useCallback(
    (selectedTime) => {
      let { endDate } = selectedDate || {};
      setStartTime(moment(selectedTime).format("hh:mm A"));
      changeTime(selectedTime, endDate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate]
  );

  const handleChangeEndTime = useCallback(
    (selectedTime) => {
      let { startDate } = selectedDate || {};
      setEndTime(moment(selectedTime).format("hh:mm A"));
      changeTime(startDate, selectedTime);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate]
  );

  const timeBetweenStartAndEnd = useMemo(() => {
    if (selectedDate?.startDate && selectedDate?.endDate) {
      const hour = moment(selectedDate.endDate).diff(
        selectedDate.startDate,
        "hour",
        true
      );
      return hour > 0 && convertHourDurationToHourAndMinute(hour, "h");
    }
  }, [selectedDate]);

  const onChangeStartRaw = (e) => {
    e.preventDefault();
    setIsChangedHour(true);
    const newTime = e.target?.value.toUpperCase();
    if (allowedChange) {
      // setStartDate({
      //   ...currentStartDate,
      //   text: newTime
      // });
      setStartTime(newTime);
      setAllowedChange(false);
    } else if (validateInputHourFormat(newTime) || newTime === "") {
      // setStartDate({
      //   ...currentStartDate,
      //   text: newTime
      // });
      setStartTime(newTime);
    }
  };

  const onChangeEndRaw = (e) => {
    e.preventDefault();
    setIsChangedHour(true);
    const newTime = e.target?.value.toUpperCase();
    if (allowedChange) {
      setEndTime(newTime);
      setAllowedChange(false);
    } else if (validateInputHourFormat(newTime) || newTime === "") {
      setEndTime(newTime);
    }
  };

  const handleStartBlur = (e) => {
    e.preventDefault();
    let readyToSave = true,
      newTime = startTime,
      format = validateInputHourFormat(newTime),
      startDate = selectedDate?.startDate,
      endDate = selectedDate?.endDate;
    const prevStartTime = startDate ? moment(startDate).format("hh:mm A") : "";
    if (format && typeof format === "string" && newTime) {
      newTime = moment(newTime, format);
      // if (newTime.format("hh:mm A") !== startTime) {
      const newTimeSelected = moment(startDate || endDate || undefined).set({
        h: newTime.hour(),
        m: newTime.minute()
      });
      if ((startDate || endDate) && newTimeSelected.isSameOrAfter(endDate)) {
        readyToSave = false;
        showError("verification_two_object", {
          object1: "endDate",
          operator: "superiour",
          object2: "startDate"
        });
        setStartTime(prevStartTime);
      } else {
        // setStartDate({
        //   date: newTimeSelected.toDate(),
        //   text: newTimeSelected.format("hh:mm A")
        // });
        startDate = newTimeSelected.toDate();
        setStartTime(newTimeSelected.format("hh:mm A"));
        handleChangeStartTime(startDate);
      }
      // } else if (e.target?.value !== newTime.format("hh:mm A")) {
      //   // setStartDate(startDate);
      //   setStartTime(prevStartTime);
      // }
    } else {
      // setStartDate(startDate);
      readyToSave = false;
      setStartTime(prevStartTime);
    }
    if (readyToSave) {
      if (e.target?.outerText?.trim() === titleButtonSave?.trim()) {
        onAddClicked(startDate, endDate);
      } else {
        setIsChangedHour(false);
      }
    }
  };

  const handleEndBlur = async (e) => {
    e.preventDefault();
    let readyToSave = true,
      newTime = endTime,
      format = validateInputHourFormat(newTime),
      startDate = selectedDate?.startDate,
      endDate = selectedDate?.endDate;
    const prevEndTime = endDate ? moment(endDate).format("hh:mm A") : "";
    if (format && typeof format === "string" && newTime) {
      newTime = moment(newTime, format);

      const newTimeSelected = moment(endDate || startDate || undefined).set({
        h: newTime.hour(),
        m: newTime.minute()
      });
      if ((startDate || endDate) && newTimeSelected.isSameOrBefore(startDate)) {
        readyToSave = false;
        showError("verification_two_object", {
          object1: "endDate",
          operator: "superiour",
          object2: "startDate"
        });
        setEndTime(prevEndTime);
      } else {
        // const lastShift = await WorkingHour.getLastShiftByEmployer(idEmployer);
        // const workingHour = lastShift?.data?.data;
        // let lastStartDate = null;
        // if (workingHour && !workingHour.endDate) {
        //   lastStartDate = getDateToRender(workingHour.startDate, "UTC");
        // }
        // const formattedStartDate = getDateToRender(startDate, "UTC");
        // const formattedEndDate = getDateToRender(newTimeSelected, "UTC");
        // if (
        //   lastStartDate &&
        //   ((formattedStartDate &&
        //     moment(formattedStartDate).isAfter(lastStartDate)) ||
        //     (endDate && moment(formattedEndDate).isAfter(lastStartDate)))
        // ) {
        //   readyToSave = false;
        //   showError("timeclock_still_spinning");
        //   setEndTime(prevEndTime);
        // } else {
          endDate = newTimeSelected.toDate();
          setEndTime(newTimeSelected.format("hh:mm A"));
          handleChangeEndTime(endDate);
        // }
      }
    } else {
      readyToSave = false;
      setEndTime(prevEndTime);
    }
    if (readyToSave) {
      if (e.target?.outerText?.trim() === titleButtonSave?.trim()) {
        onAddClicked(startDate, endDate);
      } else {
        setIsChangedHour(false);
      }
    }
  };
  const className = {
    normal:
      "arh-w-full arh-border-0 arh-py-0 arh-px-2 arh-text-center arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor",
    invalid:
      "arh-w-full arh-border-0 arh-py-0 arh-px-2 arh-text-center arh-font-poppins arh-text-xs arh-font-mediumt arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput"
  };
  return (
    <div className="w-full">
      <label
        className={`
                  arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium 
                  ${invalid ? " arh-text-[#F60A20] " : " arh-text-black "}
              `}
      >
        {label}
      </label>
      <div
        className={`detail_heure  arh-flex arh-w-full  arh-items-center arh-justify-between !arh-rounded ${parentClass}
        ${
          trackingHours || disabled ? "readonly_picker arh-bg-greyDisabled" : ""
        }`}
      >
        <div
          className={`input_detail timepicker_new timepicker_modal_resume ${classSelector}`}
        >
          <TimePickerFade
            selected={selectedDate?.startDate}
            value={startTime}
            onChange={handleChangeStartTime}
            invalid={invalid}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="hh:mm a"
            timeFormat="hh:mm a"
            readOnly={trackingHours || disabled}
            onClickOutside={handleStartBlur}
            popperClassName="popperResumeHour"
            onChangeRaw={onChangeStartRaw}
            onKeyDown={handleKeyDown}
            className={`arh-outline-none disabled:arh-pointer-events-none disabled:arh-cursor-none read-only:arh-cursor-default read-only:arh-border-greyDisabledBorder read-only:arh-bg-greyDisabled read-only:arh-text-greyDisabledText read-only:placeholder:arh-text-greyDisabledText ${
              invalid ? className["invalid"] : className["normal"]
            }`}
          />
        </div>

        {hasEndDate && (
          <>
            <span>-</span>
            <div
              className={`input_detail timepicker_new timepicker_modal_resume ${classSelector}`}
            >
              <TimePickerFade
                selected={selectedDate?.endDate}
                value={endTime}
                invalid={invalid}
                onChange={handleChangeEndTime}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm a"
                timeFormat="hh:mm a"
                readOnly={trackingHours || disabled}
                onClickOutside={handleEndBlur}
                popperClassName={"popperResumeHour"}
                onChangeRaw={onChangeEndRaw}
                onKeyDown={handleKeyDown}
                className={`arh-outline-none disabled:arh-pointer-events-none disabled:arh-cursor-none read-only:arh-cursor-default read-only:arh-border-greyDisabledBorder read-only:arh-bg-greyDisabled read-only:arh-text-greyDisabledText read-only:placeholder:arh-text-greyDisabledText ${
                  invalid ? className["invalid"] : className["normal"]
                }`}
              />
            </div>
            <div className="arh-flex arh-w-24 arh-items-center arh-justify-between arh-rounded-[40px] arh-bg-[#fdf6f1] arh-px-[0.563rem] arh-py-[0.313rem]">
              <img
                src={imgClock}
                alt=""
              />
              <span className="arh-font-poppins arh-text-xs arh-font-semibold arh-text-[#EA8A60]">
                {timeBetweenStartAndEnd || "00h00"}
              </span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InputTimePickerGroup;
