import React, { useState, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../assets/css/dashboard/dashboard.css";
import ListboxAssignation from "./index";
import { getTaskWithParents } from "../../../helpers/assignationAndDetail";
import isEqual from "lodash/isEqual"; 

const AssignationAndDetails = ({
  detail,
  assignation,
  setAssignation,
  handleChangeDetails,
  mutableProjects,
  showDetails = true,
  classParentAssignation='arh-mb-2'
}) => {
  const { t } = useTranslation("pointage.webCam");
  const [selectedElements, setSelectedElements] = useState([]);

  useEffect(() => {
    if (
      assignation?._id &&
      mutableProjects?.length &&
      !selectedElements?.length
    ) {
      // find tasks tree by name
      const selectedTasks =
        assignation._id === "formation"
          ? [assignation]
          : getTaskWithParents(mutableProjects, assignation._id);

      setSelectedElements(selectedTasks);
    }
    // eslint-disable-next-line
  }, [assignation, mutableProjects]);

  const handleSelectedValue = (value) => {
    let valueToSave = [...value];
    if(isEqual(value, selectedElements)) {
      valueToSave.pop();
      setSelectedElements(valueToSave);
    } else {
      setSelectedElements(valueToSave);
    }
  }

  return (
    <>
      <div className={classParentAssignation}>
        <label className="arh-flex arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-mb-1 arh-text-black ">
          {t(t("Assignation"))}
        </label>
        <ListboxAssignation
          placeholder={t("Assignation")}
          name="Assignation"
          optionList={mutableProjects}
          selectedValue={selectedElements}
          setSelectedValue={handleSelectedValue}
          setAssignation={setAssignation}
          color="checked:arh-bg-orangeColor"
          subItemAttr="childElements"
          showReset
          readOnly={true}
        />
      </div>
      {showDetails && (
        <div className="arh-w-full">
          <label className="arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-text-black ">
            {t("Détails")}
          </label>
          <div className="arh-w-full">
            <textarea
              onChange={handleChangeDetails}
              className="arh-w-full arh-py-3 arh-px-6 arh-h-24 arh-resize-none arh-border arh-border-solid arh-border-[#ECECEC] arh-font-poppins arh-font-normal arh-text-xs  arh-text-black placeholder:arh-text-[#ABABAB]"
              defaultValue={detail}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(AssignationAndDetails);
