import React, { useState, useEffect, memo, useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
	useForm,
	FormProvider,
	Controller,
	useFieldArray,
} from "react-hook-form";

import "../../assets/css/components/dateRange.css";
import "../../assets/css/dashboard/dashboard.css";
import "../../assets/css/pages/heureTravaillees/heureTravaillees.css";

import ModalIndex from "./layout/index";
import DefaultDatePicker from "../dateRange/DefaultDatePicker";
import TimePicker from "../dateRange/TimePickerFade";
import AddButton from "../BtnPointeuse/AddButton";
import { WorkingHour } from "../../services";

import {
	scrollIntoView,
	validateInputHourFormat,
} from "../../helpers/_functions";

import BlocHourItem from "./BlocHourItem";

const defaultInitialHour = "08:00 AM",
	hourFormat = "hh:mm A";

const SaveMyWorkedHours = ({
	isOpen,
	toggle,
	employeId,
	canPointing,
	showAssignation,
	saveWorkingHours,
	hoursWork,
	showError,
	workedHoursTypes,
	mutableProjects,
}) => {
	const {
		control,
		setValue,
		getValues,
		handleSubmit,
		reset,
		...formMethodRest
	} = useForm();

	// const allowAddFuturWorkingHours = useReduxSelector(
	//   ({ userConnected }) => userConnected.allowAddFuturWorkingHours
	// );

	const { fields, append, remove } = useFieldArray({
		control,
		name: "WorkingHours",
		rules: {
			required: true,
			minLength: 1,
			// validate: (values) => {
			//   if(allowAddFuturWorkingHours) return true;
			//   console.log(values);
			//   return true;
			// }
		},
	});
	const { t } = useTranslation("timeClock");

	const [isNotReady, setOnLoading] = useState(false);
	const [initialHour, setInitialHour] = useState(defaultInitialHour);
	const [oneMonthShiftHour, setOneMonthShiftHour] = useState([]);
	const [isEditedHour, setIsEditedHour] = useState(false);
	const newBlocHourItemRef = useRef();

	useEffect(() => {
		if (canPointing && isNotReady) setOnLoading(false);
		// eslint-disable-next-line
	}, [canPointing]);

	useEffect(() => {
		const fetchData = async () => {
			setOnLoading(true);
			let defaultDatetime = moment(defaultInitialHour, hourFormat);
			let WorkingHours = [];
			if (hoursWork?.startDate) {
				const workingHour = {
					durationHours: moment(hoursWork.endDate).diff(
						hoursWork.startDate,
						"hours"
					),
					durationMinutes: moment(hoursWork.endDate).diff(hoursWork.startDate, "minutes") % 60,
					detail: hoursWork.detail,
					assignation: hoursWork.EmployerAssignment || {},
					EmployerAssignment: hoursWork.EmployerAssignment,
				};
				WorkingHours.push(workingHour);
			}
			let lastShift = (await WorkingHour.getLastEndDateByEmploye(employeId, moment.tz.guess()))?.data?.data?.list || [];
			setOneMonthShiftHour(lastShift);
			const dateToCheck = moment().format("YYYY-MM-DD");
			const foundObject = lastShift.find((item) => item.day === dateToCheck);
			if (foundObject) {
				const endDate = moment(foundObject.endDate);
				if (defaultDatetime.isBefore(endDate)) {
					let hours = endDate.hours() + Math.floor(endDate.minutes() / 60);
					defaultDatetime = endDate.set({
						h: hours,
						m: endDate.minutes() % 60,
						ms: 0,
					});
				}
			}
			reset({
				initialDate: defaultDatetime.toDate(),
				initialHour: defaultDatetime.toDate(),
				WorkingHours,
				Timezone: moment.tz.guess(),
				Employer: employeId,
				Enterprise: localStorage.getItem("enterpriseId"),
			});
			setOnLoading(false);
			setInitialHour(defaultDatetime.format(hourFormat));
			setIsEditedHour(false);
		};
		if(isOpen) {
			fetchData();
		} else {
			reset({})
		}
		// eslint-disable-next-line
	}, [isOpen]);

	const handleChangeHourRaw = (e) => {
		e.preventDefault();
		const newTime = e.target?.value.toUpperCase();
		if (validateInputHourFormat(newTime) || newTime === "") {
			setInitialHour(newTime);
			setIsEditedHour(true);
		}
	};

	const handleBlurHour = (e) => {
		e.preventDefault();
		let format = validateInputHourFormat(initialHour);
		if (typeof format === "string" && initialHour) {
			const newTime = moment(initialHour, format);
			setInitialHour(newTime.format(hourFormat));
		} else {
			setInitialHour(moment(getValues("initialHour")).format(hourFormat));
		}
		setIsEditedHour(true);
	};

	const addNewWorkingHour = useCallback(() => {
		append({
			durationHours: 0,
			durationMinutes: 30,
			assignation: {},
			detail: "",
		});
		setTimeout(() => scrollIntoView(newBlocHourItemRef.current), 100);
		// eslint-disable-next-line
	}, []);

	const onSubmitError = (errors) => {
		// console.log(errors);
		if (errors?.WorkingHours?.root?.type === "required")
			return showError("Veillez ajouter au moins un horaire");
		showError("field_obligatory");
	};

	const onAddNewError = (errors) => {
		if (errors?.WorkingHours?.root?.type === "required")
			return addNewWorkingHour();
		showError("field_obligatory");
	};

	const handleSetDateValue = (date) => {
		const dateToCheck = moment(date).format("YYYY-MM-DD"),
			minShift = moment().subtract(1, "months").format("YYYY-MM-DD"),
			maxShift = moment().add(1, "weeks").format("YYYY-MM-DD");
		const foundObject = oneMonthShiftHour.find((item) => item?.day === dateToCheck);
		let defaultDatetime = moment(dateToCheck + " " + defaultInitialHour, "YYYY-MM-DD " + hourFormat);
		if (minShift < dateToCheck && dateToCheck < maxShift) {
			if (foundObject) {
				const endDate = moment(foundObject?.endDate);
				if (defaultDatetime.isBefore(endDate)) {
					let minutes = endDate.minutes() + 15 - (endDate.minutes() % 15);
					let hours = endDate.hours() + Math.floor(minutes / 60);
					defaultDatetime = endDate.set({
						h: hours,
						m: minutes % 60,
						ms: 0,
					});
					setInitialHour(defaultDatetime.format(hourFormat));
					reset({
						initialDate: defaultDatetime.toDate(),
						initialHour: defaultDatetime.toDate()
					});
					setIsEditedHour(false);
				}
			} else if (!isEditedHour) {
				setInitialHour(defaultDatetime.format(hourFormat));
				reset({
					initialDate: defaultDatetime.toDate(),
					initialHour: defaultDatetime.toDate()
				});
				setIsEditedHour(false);
			}
		} else {
			WorkingHour.getLastEndDateByEmploye(
				employeId,
				moment.tz.guess(),
				{ date: dateToCheck }
			).then((response) => {
				if (response.data?.data?.list?.endDate) {
					const endDate = moment(response.data?.data?.list?.endDate);
					let hours = endDate.hours() + Math.floor(endDate.minutes() / 60);
					defaultDatetime = endDate.set({
						h: hours,
						m: endDate.minutes() % 60,
						ms: 0,
					});
					setInitialHour(defaultDatetime.format(hourFormat));
					reset({
						initialDate: defaultDatetime.toDate(),
						initialHour: defaultDatetime.toDate()
					});
					setIsEditedHour(false);
				} else if (!isEditedHour) {
					setInitialHour(defaultDatetime.format(hourFormat));
					reset({
						initialDate: defaultDatetime.toDate(),
						initialHour: defaultDatetime.toDate()
					});
					setIsEditedHour(false);
				}
			});
		}
	}

	return (
		<ModalIndex
			isOpen={isOpen}
			onClickClose={toggle}
			title={t("Enregistrer des heures de travail")}
			// buttonOptions={{
			//   click: handleSubmit(saveWorkingHours, onSubmitError),
			//   color: "orange",
			//   title: t("Enregistrer")
			// }}
			// buttonCancelOptions={{
			//   click: toggle,
			//   color: "outlined",
			//   title: t("Annuler")
			// }}
			titleButtonSave="Ajouter"
			titleButtonCancel="Annuler"
			onClickSave={handleSubmit(saveWorkingHours, onSubmitError)}
			typeBtn="doubleBtnSmallSpace"
			widthModal="arh-max-w-[36rem]"
			buttonColorClass="arh-bg-[#fcb698] arh-border-[#fcb698] hover:arh-bg-[#ffa680] hover:arh-border-[#ffa680]"
			isNotReady={isNotReady}
		>
			<div className="enregistrement_content arh-relative">
				<div className="blackSvgColor calendar_with_btn calendar_with_btn--orange datepicker_vacation arh-dateRange-saveHours arh-static arh-mb-2 arh-flex-[0.5_0.5_0%]">
					<label className="arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
						{t("Date")}
					</label>
					<Controller
						control={control}
						name="initialDate"
						defaultValue={moment(defaultInitialHour, hourFormat).toDate()}
						rules={{ required: true }}
						render={({
							field: { onChange, value, name },
							fieldState: { invalid },
						}) => (
							<DefaultDatePicker
								className="datepickerDropdown GreenThemeSelect"
								name={name}
								placeholder={t("Date")}
								dateValue={value}
								invalid={invalid}
								setDateValue={(date) => {
									handleSetDateValue(date);
									onChange(date);
								}}
								classPosition="calendar_right arh-w-full"
							/>
						)}
					/>
				</div>
				<div className="arh-mb-2 arh-w-full">
					<Controller
						control={control}
						name="initialHour"
						defaultValue={moment(defaultInitialHour, hourFormat).toDate()}
						rules={{ required: true }}
						render={({
							field: { onChange, value, name },
							fieldState: { invalid },
						}) => (
							<div className="w-full">
								<label
									className={`
                          arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium 
                          ${invalid
											? " arh-text-[#F60A20] "
											: " arh-text-black "
										}
                      `}
								>
									{t("Heure d'ajout initial")}
								</label>
								<div className="detail_heure arh-mb-2 arh-flex arh-w-full  arh-items-center arh-justify-between !arh-rounded !arh-py-3 !arh-px-6">
									<div className="input_detail timepicker_new timepicker_modal_resume">
										<TimePicker
											selected={value}
											name={name}
											value={initialHour}
											onChange={(value) => {
												onChange(value);
												setInitialHour(moment(value).format(hourFormat));
												setIsEditedHour(true);
											}}
											showTimeSelect={true}
											showTimeSelectOnly={true}
											timeIntervals={15}
											timeCaption="Time"
											dateFormat="hh:mm a"
											timeFormat="hh:mm a"
											onClickOutside={handleBlurHour}
											popperClassName="popperResumeHour"
											onChangeRaw={handleChangeHourRaw}
											className="arh-w-full arh-border-0 arh-py-0 !arh-px-0 !arh-text-left arh-font-poppins !arh-text-xs arh-font-medium arh-text-darkColor"
										/>
									</div>
								</div>
							</div>
						)}
					/>
				</div>

				<div className="arh-mt-8 arh-mb-3 arh-flex arh-w-full arh-items-center arh-justify-between arh-rounded-md arh-bg-greyColor arh-py-2.5 arh-px-3.5">
					<h4 className="arh-m-0 arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor">
						{t("Ajouter un horaire")}
					</h4>
					<AddButton
						color="arh-bg-orangeColor hover:arh-bg-loaderOrange"
						onClick={handleSubmit(addNewWorkingHour, onAddNewError)}
					/>
				</div>

				<FormProvider
					control={control}
					setValue={setValue}
					getValues={getValues}
					handleSubmit={handleSubmit}
					reset={reset}
					{...formMethodRest}
				>
					{fields?.length > 0 &&
						fields.map((workingHour, index) => (
							<BlocHourItem
								key={workingHour.id}
								t={t}
								index={index}
								workingHour={workingHour}
								mutableProjects={mutableProjects}
								workedHoursTypes={workedHoursTypes}
								showAssignation={showAssignation}
								removeBlocItem={remove}
								ItemRef={
									index === fields.length - 1 ? newBlocHourItemRef : null
								}
							/>
						))}
				</FormProvider>
			</div>
		</ModalIndex>
	);
};

export default memo(SaveMyWorkedHours);
